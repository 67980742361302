import Http from "utils/Http";
import { IS_USER_LOGGED_IN_FAILURE, IS_USER_LOGGED_IN_SUCCESS } from "../../constants/actiontype";

function authSuccess(data) {
  return {
    type: IS_USER_LOGGED_IN_SUCCESS,
    payload: data,
  };
}

function authFailuer(error) {
  return {
    type: IS_USER_LOGGED_IN_FAILURE,
    payload: error,
  };
}

export function authLogin(user) {
  return (dispatch) => {
    Http.get(`by_user_token`)
      .then((response) => {
        if (response) {
          const { success, result, message } = response.data;

          if (success) {
            return dispatch(authSuccess(result[0]));
          }
          return dispatch(authFailuer(message));
        } else {
          const error = new Error("response fail!");
          throw error.message;
        }
      })
      .catch((error) => dispatch(authFailuer(error)));
  };
}
