/* eslint-disable no-console */
import axios from "axios";

if (process.env.NODE_ENV !== "development") {
  axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
} else {
  axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
}

if (localStorage.getItem("auth")) {
  axios.defaults.headers.common["Authorization"] = `${localStorage.getItem("auth")}`;
}

export default axios;
