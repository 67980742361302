import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { authLogin } from "../../../redux/actions/user/isUserLogin.action";

function PlayerGraphsProtected(props) {
  const Cmp = props.component;

  const error = props.comparePlayerDetials.error;

  // console.log(error, "error");
  useEffect(() => {
    const { isUserLogin } = props;
    const { login } = isUserLogin;

    if (login === true) {
    } else if (login === false) {
    } else {
      props.authLogin();
    }

    return () => {};
  }, []);

  return <div> {error === true ? <Redirect to="/app/Compare" /> : <Cmp />} </div>;
}

const mapStateToProps = (state) => {
  const { isUserLogin, comparePlayerDetials } = state;

  return {
    isUserLogin,
    comparePlayerDetials,
  };
};

const mapDispatchToProps = {
  authLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlayerGraphsProtected);
