import { PLAYER_DATA_SUCCESS, PLAYER_DATA_FAILURE } from "../../../constants/actiontype";

const error = new Error("admin reducer error");

const initialState = {
  error: true,
  data: [],
  isLoading: true,
  errorMessage: error.message,
};

const PlayerMasterData = (state = initialState, action) => {
  switch (action.type) {
    case PLAYER_DATA_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        error: false,
        data: action.payload,
      });
    // return list(state, action.payload)

    // case PLAYER_ADD_SUCCESS:
    //   return add(state, action.payload);

    case PLAYER_DATA_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        error: true,
        data: null,
        errorMessage: action.payload,
      });

    default:
      return state;
  }
};

// function add(state, payload) {
//   const data = state.data.find((item) => item._id === payload._id);

//   if (!data) {
//     const data = [...state.data, payload];

//     return Object.assign({}, state, { data });
//   }
// }

export default PlayerMasterData;
