import { ABOUT_SUCCESS, ABOUT_EDIT_SUCCESS, ABOUT_FAILURE } from "../../../constants/actiontype";

const error = new Error("admin reducer error");

const initialState = {
  error: true,
  data: [],
  isLoading: true,
  errorMessage: error.message,
};

const AboutMasterData = (state = initialState, action) => {
  switch (action.type) {
    case ABOUT_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        error: false,
        data: action.payload,
      });
    // return list(state, action.payload)

    case ABOUT_EDIT_SUCCESS:
      return update(state, action.payload);

    case ABOUT_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        error: true,
        data: null,
        errorMessage: action.payload,
      });
    default:
      return state;
  }
};

function update(state, payload) {
  const data = state.data.map((obj) => {
    if (obj._id === payload._id) {
      return { ...obj, ...payload };
    }
    return obj;
  });

  return Object.assign({}, state, { data });
}

export default AboutMasterData;
