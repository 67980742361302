import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        {/* <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))} /> */}
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/login-2`} component={lazy(() => import(`./authentication/login-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register-1`} component={lazy(() => import(`./authentication/register-1`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register`} component={lazy(() => import(`./authentication/register-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/forgot_password`} component={lazy(() => import(`./authentication/forgot-password`))} />

        <Route path={`${AUTH_PREFIX_PATH}/mobile`} component={lazy(() => import(`./authentication/forgot-password/mobilesend`))} />
        <Route path={`${AUTH_PREFIX_PATH}/email`} component={lazy(() => import(`./authentication/forgot-password/emailsend`))} />
        <Route path={`${AUTH_PREFIX_PATH}/otp`} component={lazy(() => import(`./authentication/forgot-password/mobileotpsend`))} />

        <Route path={`${AUTH_PREFIX_PATH}/reset-password/:otp`} component={lazy(() => import(`./authentication/reset-password`))} />

        {/* <Route
          path={`${AUTH_PREFIX_PATH}/reset-password`}
          component={lazy(() => import(`./authentication/reset-password`))}
        /> */}
        <Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`./errors/error-page-1`))} />

        <Route path={`${AUTH_PREFIX_PATH}/error-1`} component={lazy(() => import(`../app-views/home`))} />

        <Redirect from={`${AUTH_PREFIX_PATH}/login`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
