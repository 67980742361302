import { GET_LOGIN_SUCCESS, GET_LOGIN_FAILURE } from "../../constants/actiontype";

const error = new Error("admin reducer error");
const initialState = {
  isLoading: true,
  error: false,
  data: [],
  errorMessage: error.message,
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN_SUCCESS:
      return Object.assign({}, state, { isLoading: false, error: false, errorMessage: null, data: action.payload });
    case GET_LOGIN_FAILURE:
      return Object.assign({}, state, { isLoading: false, data: null, errorMessage: action.payload });
    default:
      return state;
  }
};

export default LoginReducer;
