import { COACH_PLAYER_DATA_SUCCESS, COACH_PLAYER_EDIT_SUCCESS, COACH_PLAYER_DELETE_SUCCESS, COACH_PLAYER_ADD_SUCCESS, COACH_PLAYER_DATA_FAILURE } from "../../../constants/actiontype";

const error = new Error("admin reducer error");

const initialState = {
  error: true,
  data: [],
  isLoading: true,
  errorMessage: error.message,
};

const CoachPlayerMasterData = (state = initialState, action) => {
  switch (action.type) {
    case COACH_PLAYER_DATA_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        error: false,
        data: action.payload,
      });

    case COACH_PLAYER_ADD_SUCCESS:
      return add(state, action.payload);

    case COACH_PLAYER_EDIT_SUCCESS:
      return update(state, action.payload);

    case COACH_PLAYER_DELETE_SUCCESS:
      return remove(state, action.payload);

    case COACH_PLAYER_DATA_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        error: true,
        data: null,
        errorMessage: action.payload,
      });

    default:
      return state;
  }
};

function add(state, payload) {
  const data = state.data.find((item) => item.playerID === payload.playerID);

  if (!data) {
    const data = [...state.data, payload];

    return Object.assign({}, state, { data });
  }
}

function update(state, payload) {
  const data = state.data.map((obj) => {
    if (obj.playerID === payload.playerID) {
      return { ...obj, ...payload };
    }
    return obj;
  });

  return Object.assign({}, state, { data });
}

function remove(state, playerID) {
  const data = state.data.filter((obj) => obj.playerID !== playerID);

  return Object.assign({}, state, { data });
}

export default CoachPlayerMasterData;
