import { DEVICE_HISTROY_ONE_FAILURE, DEVICE_HISTROY_ONE_SUCCESS } from "../../../constants/actiontype";

const error = new Error("admin reducer error");

const initialState = {
  error: true,
  data: [],
  isLoading: true,
  errorMessage: error.message,
};

const DeviceOneData = (state = initialState, action) => {
  switch (action.type) {
    case DEVICE_HISTROY_ONE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        error: false,
        data: action.payload,
      });

    case DEVICE_HISTROY_ONE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        error: true,
        data: null,
        errorMessage: action.payload,
      });
    default:
      return state;
  }
};
export default DeviceOneData;
