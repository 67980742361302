import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import Protectd from "./Protected/index";
import Protectds from "./Protected/playergraph";
import ProtectdOne from "./Protected/playeronehraph";
import ProtectedSimple from "./Protected/protected";

export const AppViews = (props) => {
  const history = useHistory();
  const getRole = () => {
    return parseInt(localStorage.getItem("role")) || 0;
  };

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home/`}>
          <ProtectedSimple component={lazy(() => import(`./home`))} role={getRole()} history={history} />
        </Route>

        <Route path={`${APP_PREFIX_PATH}/user_manage`}>
          <ProtectedSimple component={lazy(() => import(`./Master/User-Master`))} role={getRole()} history={history} />
        </Route>
        <Route path={`${APP_PREFIX_PATH}/cochplayer/:coachId`}>
          <Protectd component={lazy(() => import(`./Master/User-Master/cochplayer`))} role={getRole()} history={history} />
        </Route>
        <Route path={`${APP_PREFIX_PATH}/DevicesHistroy/:deviceID`}>
          <Protectd component={lazy(() => import(`./Device-History/deviceoneview`))} role={getRole()} history={history} />
        </Route>
        {/* about us edit */}
        <Route path={`${APP_PREFIX_PATH}/node_master`}>
          <ProtectedSimple component={lazy(() => import(`./Master/Node-Master`))} role={getRole()} history={history} />
        </Route>
        <Route path={`${APP_PREFIX_PATH}/ground`}>
          <ProtectedSimple component={lazy(() => import(`./Master/Ground`))} role={getRole()} history={history} />
        </Route>
        <Route path={`${APP_PREFIX_PATH}/device`}>
          <ProtectedSimple component={lazy(() => import(`./Master/Device`))} role={getRole()} history={history} />
        </Route>

        <Route path={`${APP_PREFIX_PATH}/sprit_threshol`}>
          <ProtectedSimple component={lazy(() => import(`./Sprit-Threshol`))} role={getRole()} history={history} />
        </Route>
        <Route path={`${APP_PREFIX_PATH}/profile/editProfile`}>
          <Protectd component={lazy(() => import(`./profile/EditProfile`))} role={getRole()} history={history} />
        </Route>
        <Route path={`${APP_PREFIX_PATH}/profile/changePassword`}>
          <Protectd component={lazy(() => import(`./profile/ChangePassword`))} role={getRole()} history={history} />
        </Route>
        <Route path={`${APP_PREFIX_PATH}/TopPlayers/:auth`}>
          <Protectd component={lazy(() => import(`./Coach/DashBoard/topplayers`))} role={getRole()} history={history} />
        </Route>

        <Route path={`${APP_PREFIX_PATH}/device_history`}>
          <ProtectedSimple component={lazy(() => import(`./Device-History`))} role={getRole()} history={history} />
        </Route>
        <Route path={`${APP_PREFIX_PATH}/mappingg`}>
          <ProtectedSimple component={lazy(() => import(`./Mappingg`))} role={getRole()} history={history} />
        </Route>
        <Route path={`${APP_PREFIX_PATH}/about`}>
          <ProtectedSimple component={lazy(() => import(`./aboutUs`))} role={getRole()} history={history} />
        </Route>
        <Route path={`${APP_PREFIX_PATH}/contact`}>
          <ProtectedSimple component={lazy(() => import(`./ContactForm`))} role={getRole()} history={history} />
        </Route>

        <Route path={`${APP_PREFIX_PATH}/dashboard`}>
          <ProtectedSimple component={lazy(() => import(`./Coach/DashBoard`))} role={getRole()} history={history} />
        </Route>

        <Route path={`${APP_PREFIX_PATH}/teamMaster`}>
          <ProtectedSimple component={lazy(() => import(`./Coach/TeamMaster`))} role={getRole()} history={history} />
        </Route>
        <Route path={`${APP_PREFIX_PATH}/playerMaster`}>
          <ProtectedSimple component={lazy(() => import(`./Coach/Players`))} role={getRole()} history={history} />
        </Route>

        {/* compare */}
        <Route path={`${APP_PREFIX_PATH}/compare`}>
          <ProtectedSimple component={lazy(() => import(`./Coach/ComparePlayer/index`))} role={getRole()} history={history} />
        </Route>

        <Route path={`${APP_PREFIX_PATH}/Players/:playerID`}>
          <Protectd component={lazy(() => import(`./Coach/Players/PlayerFullDetails`))} role={getRole()} history={history} />
        </Route>

        <Route path={`${APP_PREFIX_PATH}/contactUs`}>
          <ProtectedSimple component={lazy(() => import(`./Coach/ContactUs`))} role={getRole()} history={history} />
        </Route>

        <Route path={`${APP_PREFIX_PATH}/PlayerOneGraph/:id`}>
          <ProtectdOne component={lazy(() => import(`./Coach/Players/playergraph`))} role={getRole()} history={history} />
        </Route>

        <Route path={`${APP_PREFIX_PATH}/comparePlayer/:id`}>
          <Protectds component={lazy(() => import(`./Coach/ComparePlayer/playergraph`))} role={getRole()} history={history} />
        </Route>

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
