import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import LoginReducer from "../reducers/user/login.reducer";
import IsUserLogin from "../reducers/user/isUserLogin.reducer";
import Logout from "../reducers/user/logout.reducer";
import Dashboard from "../reducers/dashboard/dashboard.reducer";

import NodeMasterData from "./foottrax/nodemaster/node.reducer";
import GroundMasterData from "./foottrax/groundmaster/ground.reducer";
import CoachMasterData from "./foottrax/coach-playermaster/coach.reducer";
import PlayerMasterData from "./foottrax/coach-playermaster/player.reducer";
import TeamMasterData from "./foottrax/coach-playermaster/team.reducer";
import CoachPlayerMasterData from "./foottrax/coach-playermaster/coachplayer.reducer";
import MappingMasterData from "./foottrax/mapping/mapping.reducer";
import SprintMasterData from "./foottrax/SpritThresholMaster/sprit.reducer";
import DeviceHistroyData from "./foottrax/devicehistroy/devicehistroy.reducer";
import AboutMasterData from "./foottrax/aboutus/about.reducer";
import ContactMasterData from "./foottrax/contactus/contact.reducer";
import DeviceOneData from "./foottrax/devicehistroy/deviceone.reducer";
import NodeMapping from "./foottrax/mapping/mapping.node.reducer";
import MappingDevice from "./foottrax/mapping/mapping.device.reducer";
import MappingAllowData from "./foottrax/mapping/mappingallow.reducer";
//coach
import CoachTeamMaster from "./coach/TeamMaster/TeamMaster.reducer";
import CoachDashboard from "./coach/Dashboard/Dashboard.reducer";
//DEVICE
import DeviceData from "./foottrax/device/device.reducer";
// Player Full Details
import PlayerFullDetails from "./coach/PlayerFullDetails/playerFullDetails.reducer";

//compare player
import ComparePlayerDetials from "./coach/ComparePlayer/playercompare.reducer";
import TotelPlayer from "./coach/ComparePlayer/totelplayer.reducer";
import PlayerOneGraph from "./coach/playerperformance/playerperformance.reducer";
import CoachDetailsData from "./foottrax/coach-playermaster/coachdetails.reducer";
import DeviceDetailsData from "./foottrax/devicehistroy/devicedetails.reducer";
import PlayerOneDetails from "./coach/PlayerFullDetails/playeronedetails.reducer";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  loginUser: LoginReducer,
  isUserLogin: IsUserLogin,
  logout: Logout,
  dashboard: Dashboard,

  nodemasterdata: NodeMasterData,
  groundmasterdata: GroundMasterData,
  coachmasterdata: CoachMasterData,
  playermasterdata: PlayerMasterData,
  teammasterdata: TeamMasterData,
  coachplayermasterdata: CoachPlayerMasterData,
  mappingmasterdata: MappingMasterData,
  sprintmasterdata: SprintMasterData,
  devicehistroydata: DeviceHistroyData,
  aboutmasterdata: AboutMasterData,
  contactmasterdata: ContactMasterData,
  deviceonedata: DeviceOneData,
  devicedata: DeviceData,
  deviceDetailsData: DeviceDetailsData,
  mappingAllowData: MappingAllowData,
  //mapping
  nodemapping: NodeMapping,
  mappingdevice: MappingDevice,

  //coach master
  coachteammaster: CoachTeamMaster,
  coachdashboard: CoachDashboard,
  playerfulldetails: PlayerFullDetails,
  playerOneDetails: PlayerOneDetails,

  //compare player
  comparePlayerDetials: ComparePlayerDetials,
  totelPlayer: TotelPlayer,

  // one player
  playeroneGraph: PlayerOneGraph,
  coachDetailsData: CoachDetailsData,
});

export default reducers;
