import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { authLogin } from "../../../redux/actions/user/isUserLogin.action";

function Protected({ history, ...props }) {
  const Cmp = props.component;

  var auth = localStorage.getItem("auth");

  useEffect(() => {
    const { isUserLogin } = props;
    const { login } = isUserLogin;

    if (login === true) {
    } else if (login === false) {
    } else {
      props.authLogin();
    }

    return () => {};
  }, []);

  return <div> {auth ? <Cmp /> : <Redirect to="/auth/login" />} </div>;
}

const mapStateToProps = (state) => {
  const { isUserLogin } = state;

  return { isUserLogin };
};

const mapDispatchToProps = {
  authLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(Protected);
