import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import { APP_PREFIX_PATH } from "configs/AppConfig";

import { EditOutlined, LogoutOutlined, LockOutlined } from "@ant-design/icons";
import Icon from "components/util-components/Icon";

import { logoutAdmin } from "../../redux/actions/user/logout.action";
import { authLogin } from "../../redux/actions/user/isUserLogin.action";

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    // path: `${APP_PREFIX_PATH}/profileK`
    path: `${APP_PREFIX_PATH}/profile/editProfile`,
  },
  {
    title: "Change Password",
    icon: LockOutlined,
    // path: `${APP_PREFIX_PATH}/profileK`
    path: `${APP_PREFIX_PATH}/Profile/changePassword`,
  },
];

export const NavProfile = (props) => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    const { isUserLogin } = props;
    const { data } = isUserLogin;

    if (loading) {
      props.authLogin();
      if (loading) {
        setLoading(false);
      }
    }
    setList(data || []);

    return () => {};
  }, [props.isUserLogin]);

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} src={list?.profile} />
          <div className="pl-3">
            <h4 className="mb-0">
              {list?.first_name} {list?.last_name}
            </h4>
            <span className="text-muted">{list?.mobile}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item
            key={menuItem.legth + 1}
            onClick={(e) => {
              props.logoutAdmin();
            }}
          >
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          <Avatar src={list?.profile} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => {
  // console.log(state, "state");
  return {
    loginUser: state.loginUser,
    isUserLogin: state.isUserLogin,
  };
};

const mapDispatchToProps = {
  logoutAdmin,
  authLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile);
