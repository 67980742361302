import { PLAYER_PERFORMANCE_SUCCESS, PLAYER_PERFORMANCE_FAILURE } from "../../../constants/actiontype";

const error = new Error("auth login reducer error");
const initialState = {
  error: true,
  data: {},
  errorMessage: error.message,
};

const PlayerOneGraph = (state = initialState, action) => {
  switch (action.type) {
    case PLAYER_PERFORMANCE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        error: false,
        data: action.payload,
      });
    case PLAYER_PERFORMANCE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        error: true,
        data: null,
        errorMessage: action.payload,
      });
    default:
      return state;
  }
};

export default PlayerOneGraph;
