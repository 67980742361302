import { DASHBOARD_SUCCESS, DASHBOARD_FAILURE } from "../../../redux/constants/actiontype";

const initialState = {
  error: true,
  data: {},
};

const UserData = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        data: action.payload,
      });
    case DASHBOARD_FAILURE:
      return Object.assign({}, state, {
        data: null,
        errorMessage: action.payload,
      });
    default:
      return state;
  }
};

export default UserData;
