import { COACH_DASHBOARD_SUCCESS, COACH_DASHBOARD_FAILURE } from "../../../constants/actiontype";

const initialState = {
  error: true,
  data: {},
};

const CoachDashboard = (state = initialState, action) => {
  switch (action.type) {
    case COACH_DASHBOARD_SUCCESS:
      return Object.assign({}, state, {
        error: false,
        data: action.payload,
      });
    case COACH_DASHBOARD_FAILURE:
      return Object.assign({}, state, {
        data: null,
        errorMessage: action.payload,
      });
    default:
      return state;
  }
};

export default CoachDashboard;
