import { DashboardTwoTone, SnippetsTwoTone, ContactsTwoTone, ContainerTwoTone, FundTwoTone, BugTwoTone, ProfileTwoTone } from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const appsNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "Dashboard",
    icon: DashboardTwoTone,
    breadcrumb: false,
    submenu: [],
    role: [3],
  },
  {
    key: "dashboard",
    path: `${APP_PREFIX_PATH}/dashboard`,
    title: "Dashboard",
    icon: DashboardTwoTone,
    breadcrumb: false,
    submenu: [],
    role: [2],
  },

  {
    key: "master",
    path: `${APP_PREFIX_PATH}/Master`,
    title: "Pages",
    icon: "",
    breadcrumb: true,
    role: [3],
    submenu: [
      {
        key: "master",
        path: `${APP_PREFIX_PATH}/Master`,
        title: "Master",
        icon: ContainerTwoTone,
        breadcrumb: true,
        role: [3],
        submenu: [
          {
            key: "node_Master",
            path: `${APP_PREFIX_PATH}/node_master`,
            title: "Node-Master",
            icon: "",
            breadcrumb: false,
            submenu: [],
            role: [3],
          },

          {
            key: "user_Master",
            path: `${APP_PREFIX_PATH}/user_manage`,
            title: "User Master",
            icon: "",
            breadcrumb: false,
            submenu: [],
            role: [3],
          },
          {
            key: "ground",
            path: `${APP_PREFIX_PATH}/ground`,
            title: "Ground Master",
            icon: "",
            breadcrumb: false,
            submenu: [],
            role: [3],
          },
          {
            key: "device",
            path: `${APP_PREFIX_PATH}/device`,
            title: "Device Master",
            icon: "",
            breadcrumb: false,
            submenu: [],
            role: [3],
          },
        ],
      },
    ],
  },

  {
    key: "teamMaster",
    path: `${APP_PREFIX_PATH}/teamMaster`,
    title: "Team Master",
    icon: FundTwoTone,
    breadcrumb: false,
    submenu: [],
    role: [2],
  },
  {
    key: "playerMaster",
    path: `${APP_PREFIX_PATH}/playerMaster`,
    title: "Player Master",
    icon: FundTwoTone,
    breadcrumb: false,
    submenu: [],
    role: [2],
  },
  {
    key: "compare",
    path: `${APP_PREFIX_PATH}/compare`,
    title: "Compare Player Master",
    icon: FundTwoTone,
    breadcrumb: false,
    submenu: [],
    role: [2],
  },

  {
    key: "mappingg",
    path: `${APP_PREFIX_PATH}/mappingg`,
    title: "Mapping",
    icon: FundTwoTone,
    breadcrumb: false,
    submenu: [],
    role: [3],
  },

  {
    key: "sprit-Threshol",
    path: `${APP_PREFIX_PATH}/sprit_threshol`,
    title: "Sprint Threshold",
    icon: ProfileTwoTone,
    breadcrumb: false,
    submenu: [],
    role: [3],
  },

  {
    key: "device-History",
    path: `${APP_PREFIX_PATH}/device_history`,
    title: "Device History",
    icon: BugTwoTone,
    breadcrumb: false,
    submenu: [],
    role: [3],
  },

  {
    key: "about",
    path: `${APP_PREFIX_PATH}/about`,
    title: "About Us",
    icon: SnippetsTwoTone,
    breadcrumb: false,
    submenu: [],
    role: [3],
  },

  {
    key: "contact",
    path: `${APP_PREFIX_PATH}/contact`,
    title: "Contact Us",
    icon: ContactsTwoTone,
    breadcrumb: false,
    submenu: [],
    role: [3],
  },
  {
    key: "coach-Contact",
    path: `${APP_PREFIX_PATH}/contactUs`,
    title: "Contact Us",
    icon: ContactsTwoTone,
    breadcrumb: false,
    submenu: [],
    role: [2],
  },
];

const navigationConfig = [...appsNavTree];

export default navigationConfig;
