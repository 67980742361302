// LOGIN
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_LOGIN_FAILURE = "GET_LOGIN_FAILURE";

// USE LOGIN ?
export const IS_USER_LOGGED_IN_FAILURE = "IS_USER_LOGGED_IN_FAILURE";
export const IS_USER_LOGGED_IN_SUCCESS = "IS_USER_LOGGED_IN_SUCCESS";

// SET MESSAGE
export const SET_ERROR = "SET_ERROR";
export const SET_MESSAGE = "SET_MESSAGE";

//lOGOUT
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

//DASHBOARD
export const DASHBOARD_SUCCESS = "DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "DASHBOARD_FAILURE";

//NODE MASTER
export const NODE_DATA_SUCCESS = "NODE_DATA_SUCCESS";
export const NODE_ADD_SUCCESS = "NODE_ADD_SUCCESS";
export const NODE_EDIT_SUCCESS = "NODE_EDIT_SUCCESS";
export const NODE_DELETE_SUCCESS = "NODE_DELETE_SUCCESS";
export const NODE_DATA_FAILURE = "NODE_DATA_FAILURE";

//GROUND MASTER
export const GROUND_DATA_SUCCESS = "GROUND_DATA_SUCCESS";
export const GROUND_ADD_SUCCESS = "GROUND_ADD_SUCCESS";
export const GROUND_EDIT_SUCCESS = "GROUND_EDIT_SUCCESS";
export const GROUND_DELETE_SUCCESS = "GROUND_DELETE_SUCCESS";
export const GROUND_DATA_FAILURE = "GROUND_DATA_FAILURE";

//GROUND MASTER
export const DEVICES_DATA_SUCCESS = "DEVICES_DATA_SUCCESS";
export const DEVICES_ADD_SUCCESS = "DEVICES_ADD_SUCCESS";
export const DEVICES_EDIT_SUCCESS = "DEVICES_EDIT_SUCCESS";
export const DEVICES_DELETE_SUCCESS = "DEVICES_DELETE_SUCCESS";
export const DEVICES_DATA_FAILURE = "DEVICES_DATA_FAILURE";

//COACH MASTER
export const COACH_DATA_SUCCESS = "COACH_DATA_SUCCESS";
export const COACH_DATA_FAILURE = "COACH_DATA_FAILURE";

//PLAYER DETAILS
export const PLAYER_DETAILS_DATA_SUCCESS = "PLAYER_DETAILS_DATA_SUCCESS";
export const PLAYER_DETAILS_DATA_FAILURE = "PLAYER_DETAILS_DATA_FAILURE";

//ALL PLAYER MASTER
export const PLAYER_DATA_SUCCESS = "PLAYER_DATA_SUCCESS";
export const PLAYER_DATA_FAILURE = "PLAYER_DATA_FAILURE";

// TOTEL TEAM
export const TEAM_DATA_SUCCESS = "TEAM_DATA_SUCCESS";
export const TEAM_DATA_FAILURE = "TEAM_DATA_FAILURE";

//COACH DETAILS
export const COACH_DETAILS_DATA_SUCCESS = "COACH_DETAILS_DATA_SUCCESS";
export const COACH_DETAILS_DATA_FAILURE = "COACH_DETAILS_DATA_FAILURE";

//COACH BY PLAYER
export const COACH_PLAYER_DATA_SUCCESS = "COACH_PLAYER_DATA_SUCCESS";
export const COACH_PLAYER_ADD_SUCCESS = "COACH_PLAYER_ADD_SUCCESS";
export const COACH_PLAYER_EDIT_SUCCESS = "COACH_PLAYER_EDIT_SUCCESS";
export const COACH_PLAYER_DATA_FAILURE = "COACH_PLAYER_DATA_FAILURE";
export const COACH_PLAYER_DELETE_SUCCESS = "COACH_PLAYER_DELETE_SUCCESS";

//MAPPING MASTER
export const MAPPING_DATA_SUCCESS = "TEAM_DATA_SUCCESS";
export const MAPPING_DATA_ADD_SUCCESS = "MAPPING_DATA_ADD_SUCCESS";
export const MAPPING_DATA_EDIT_SUCCESS = "MAPPING_DATA_EDIT_SUCCESS";
export const MAPPING_DATA_DELETE_SUCCESS = "MAPPING_DATA_DELETE_SUCCESS";
export const MAPPING_DATA_FAILURE = "TEAM_DATA_FAILURE";

//MAPPING EDIT
export const MAPPING_ALLOW_SUCCESS = "TEAM_ALLOW_SUCCESS";
export const MAPPING_ALLOW_FAILURE = "TEAM_ALLOW_FAILURE";

//MAPPING DEVICE AND GROUND MASTER
export const MAPPING_NODE_SUCCESS = "MAPPING_NODE_SUCCESS";
export const MAPPING_NODE_FAILURE = "MAPPING_NODE_FAILURE";
export const MAPPING_DEVICE_SUCCESS = "MAPPING_DEVICE_SUCCESS";
export const MAPPING_DEVICE_FAILURE = "MAPPING_DEVICE_FAILURE";

//SPRINT MASTER
export const SPRINT_DATA_SUCCESS = "SPRIT_DATA_SUCCESS";
export const SPRINT_ADD_SUCCESS = "SPRINT_ADD_SUCCESS";
export const SPRINT_EDIT_SUCCESS = "SPRINT_EDIT_SUCCESS";
export const SPRINT_DELETE_SUCCESS = "SPRINT_DELETE_SUCCESS";
export const SPRINT_DATA_FAILURE = "SPRIT_DATA_FAILURE";

//DEVICE-HISTROY
export const DEVICE_HISTROY_SUCCESS = "DEVICE_HISTROY_SUCCESS";
export const DEVICE_HISTROY_FAILURE = "DEVICE_HISTROY_FAILURE";

//DEVICE DETAILS
export const DEVICE_DATA_DETAILS_SUCCESS = "DEVICE_DATA_DETAILS_SUCCESS";
export const DEVICE_DATA_DETAILS_FAILURE = "DEVICE_DATA_DETAILS_FAILURE";
//DEVICE-HISTROY-ONE

export const DEVICE_HISTROY_ONE_SUCCESS = "DEVICE_HISTROY_ONE_SUCCESS";
export const DEVICE_HISTROY_ONE_FAILURE = "DEVICE_HISTROY_ONE_FAILURE";

//ABOUT US PAGE
export const ABOUT_SUCCESS = "ABOUT_SUCCESS";
export const ABOUT_EDIT_SUCCESS = "ABOUT_EDIT_SUCCESS";
export const ABOUT_FAILURE = "ABOUT_FAILURE";

//CONATCT US PAGE
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_EDIT_SUCCESS = "CONTACT_EDIT_SUCCESS";
export const CONTACT_FAILURE = "CONTACT_FAILURE";

//REGISTRATION FORM
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAILURE = "REGISTRATION_FAILURE";

//COACH ADMIN
export const TEAM_GET_SUCCESS = "TEAM_GET_SUCCESS";
export const TEAM_ADD_SUCCESS = "TEAM_ADD_SUCCESS";
export const TEAM_EDIT_SUCCESS = "TEAM_EDIT_SUCCESS";
export const TEAM_DELETE_SUCCESS = "TEAM_DELETE_SUCCESS";
export const TEAM_FAILURE = "TEAM_FAILURE";

//COACH - DASHBOARD
export const COACH_DASHBOARD_SUCCESS = "COACH_DASHBOARD_SUCCESS";
export const COACH_DASHBOARD_FAILURE = "COACH_DASHBOARD_FAILURE";

//COACH-PLAYER FULL DETAILS
export const PLAYER_FULL_DATA_SUCCESS = "PLAYER_FULL_DATA_SUCCESS";
export const PLAYER_FULL_DATA_FAILURE = "PLAYER_FULL_DATA_FAILURE";

//COMPARE PLAYER

//COMPARE PLAYER SEND DETAILS
export const PLAYER_COMPARE_DATA_SUCCESS = "PLAYER_COMPARE_DATA_SUCCESS";
export const PLAYER_COMPARE_ONE_SUCCESS = "PLAYER_COMPARE_ONE_SUCCESS";
export const PLAYER_COMPARE_DATA_FAILURE = "PLAYER_COMPARE_DATA_FAILURE";

//COMPARE PLAYER  =  GET ALL PLAYER (ADMIN-COACH)
export const TOTEL_PLAYER_SUCCESS = "TOTEL_PLAYER_SUCCESS";
export const TOTEL_PLAYER_FAILURE = "TOTEL_PLAYER_FAILURE";

//PLAYER PERFORMANCE = PLAYER PERFORMANCE BY DATE
export const PLAYER_PERFORMANCE_SUCCESS = "PLAYER_PERFORMANCE_SUCCESS";
export const PLAYER_PERFORMANCE_FAILURE = "PLAYER_PERFORMANCE_FAILURE";
