import { DEVICE_DATA_DETAILS_SUCCESS, DEVICE_DATA_DETAILS_FAILURE } from "../../../constants/actiontype";

const error = new Error("admin reducer error");

const initialState = {
  error: true,
  data: [],
  isLoading: true,
  errorMessage: error.message,
};

const DeviceDetailsData = (state = initialState, action) => {
  switch (action.type) {
    case DEVICE_DATA_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        error: false,
        data: action.payload,
      });

    case DEVICE_DATA_DETAILS_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        error: true,
        data: null,
        errorMessage: action.payload,
      });
    default:
      return state;
  }
};

export default DeviceDetailsData;
