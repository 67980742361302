import { IS_USER_LOGGED_IN_FAILURE, IS_USER_LOGGED_IN_SUCCESS } from "../../../redux/constants/actiontype";

const error = new Error("auth login reducer error");
const initialState = {
  error: "defautlt",
  errorMessage: error.message,
  data: {},
  loading: true,
  login: null,
};

const IsUserLogin = (state = initialState, action) => {
  switch (action.type) {
    case IS_USER_LOGGED_IN_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        error: "success",
        errorMessage: null,
        data: action.payload,
        login: true,
      });
    case IS_USER_LOGGED_IN_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        data: null,
        errorMessage: action.payload,
        login: false,
        error: "failer",
      });
    default:
      return state;
  }
};

export default IsUserLogin;
