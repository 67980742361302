import { LOGOUT_USER_FAILURE, LOGOUT_USER_SUCCESS } from "../../../redux/constants/actiontype";

const error = new Error("user logout reducer error");
const initialState = {
  error: true,
  errorMessage: error.message,
  data: {},

  loading: true,
};

const Logout = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER_SUCCESS:
      localStorage.removeItem("auth");
      return Object.assign({}, state, {
        loading: false,
        error: false,
        errorMessage: null,

        data: action.payload,
      });
    case LOGOUT_USER_FAILURE:
      return Object.assign({}, state, { loading: false, data: null, errorMessage: action.payload });
    default:
      return state;
  }
};

export default Logout;
