import { SPRINT_DATA_SUCCESS, SPRINT_EDIT_SUCCESS, SPRINT_DELETE_SUCCESS, SPRINT_ADD_SUCCESS, SPRINT_DATA_FAILURE } from "../../../constants/actiontype";

const error = new Error("admin reducer error");

const initialState = {
  error: true,
  data: [],
  isLoading: true,
  errorMessage: error.message,
};

const SprintMasterData = (state = initialState, action) => {
  switch (action.type) {
    case SPRINT_DATA_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        error: false,
        data: action.payload,
      });
    // return list(state, action.payload)

    case SPRINT_ADD_SUCCESS:
      return add(state, action.payload);

    case SPRINT_DELETE_SUCCESS:
      return remove(state, action.payload);

    case SPRINT_EDIT_SUCCESS:
      return update(state, action.payload);

    case SPRINT_DATA_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        error: true,
        data: null,
        errorMessage: action.payload,
      });
    default:
      return state;
  }
};

function add(state, payload) {
  const data = state.data.find((item) => item._id === payload._id);

  if (!data) {
    const data = [...state.data, payload];

    return Object.assign({}, state, { data });
  }
}

function update(state, payload) {
  const data = state.data.map((obj) => {
    if (obj._id === payload._id) {
      return { ...obj, ...payload };
    }
    return obj;
  });

  return Object.assign({}, state, { data });
}

function remove(state, id) {
  const data = state.data.filter((obj) => obj._id !== id);

  return Object.assign({}, state, { data });
}

export default SprintMasterData;
