import { TEAM_DATA_SUCCESS, TEAM_DATA_FAILURE } from "../../../constants/actiontype";

const error = new Error("admin reducer error");

const initialState = {
  error: true,
  data: [],
  isLoading: true,
  errorMessage: error.message,
};

const TeamMasterData = (state = initialState, action) => {
  switch (action.type) {
    case TEAM_DATA_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        error: false,
        data: action.payload,
      });
    // return list(state, action.payload)

    case TEAM_DATA_FAILURE:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        error: true,
        data: null,
        errorMessage: action.payload,
      });
    default:
      return state;
  }
};

export default TeamMasterData;
