import { LOGOUT_USER_FAILURE, LOGOUT_USER_SUCCESS } from "../../../redux/constants/actiontype";
import Http from "utils/Http";

function logoutSuccess(data) {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: data,
  };
}

function logoutFailuer(error) {
  return {
    type: LOGOUT_USER_FAILURE,
    payload: error,
  };
}

export function logoutAdmin() {
  const id = localStorage.getItem("id");
  return (dispatch) => {
    Http.put(`logout/${id}`)
      .then((response) => {
        console.log(response, "response api logout");
        if (response) {
          const { success, result } = response.data;

          if (success) {
            localStorage.removeItem("auth");
            localStorage.removeItem("id");
            localStorage.removeItem("role");
            window.location.reload(true);
            dispatch(logoutSuccess(result));
          }
        } else {
          const error = new Error("response fail!");
          throw error.message;
        }
      })
      .catch((error) => dispatch(logoutFailuer(error)));
  };
}
