import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { authLogin } from "../../../redux/actions/user/isUserLogin.action";

function ProtectedSimple({ history, ...props }) {

  const Cmp = props.component;

  const url = history.location.pathname;
  const role = props.role;

  const prtd = [
    {
      role: 3,
      url: "/app/mappingg",
    },
    {
      role: 3,
      url: "/app/home",
    },
    {
      role: 3,
      url: "/app/user_manage",
    },

    {
      role: 3,
      url: "/app/node_master",
    },
    {
      role: 3,
      url: "/app/ground",
    },
    {
      role: 3,
      url: "/app/device",
    },

    {
      role: 3,
      url: "/app/sprit_threshol",
    },
    {
      role: 3,
      url: "/app/about",
    },

    {
      role: 3,
      url: "/app/device_history",
    },
    {
      role: 3,
      url: "/app/contact",
    },

    {
      role: 2,
      url: "/app/dashboard",
    },

    {
      role: 2,
      url: "/app/teamMaster",
    },

    {
      role: 2,
      url: "/app/playerMaster",
    },

    {
      role: 2,
      url: "/app/compare",
    },

    {
      role: 2,
      url: "/app/contactUs",
    },
  ];

  const [cmpnt] = prtd.filter((d) => {
    return d.url === url && role === d.role;
  });

  var auth = localStorage.getItem("auth");



  useEffect(() => {
    const { isUserLogin } = props;
    const { login } = isUserLogin;

    if (login === true) {
    } else if (login === false) {
    } else {
      props.authLogin();
    }


    return () => { };
  }, []);

  return <div>

    {auth && cmpnt ? <Cmp /> : <Redirect to="/auth/login" />
    }

  </div>;
}

const mapStateToProps = (state) => {
  const { isUserLogin } = state;

  return { isUserLogin };
};

const mapDispatchToProps = {
  authLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedSimple);
